import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteComponent } from './quote.component';
import { TranslateModule } from '@ngx-translate/core';
import { SectionTitleModule } from '../../section-title/section-title.module';
import { BlockAnimationModule } from '../../../directives/block-animation/block-animation.module';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SectionTitleModule,
    BlockAnimationModule

  ],
  exports: [QuoteComponent,],
  declarations: [QuoteComponent]
})
export class QuoteModule { }
