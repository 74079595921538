import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorsCollection {
    public key = 'authors';
    public pageData = null;

    constructor(private api: ApiService) { }


    public get() {
        this.pageData = this.api.get(this.key);
        return this.pageData
    }


}

