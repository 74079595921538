import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { MetaService } from '../../meta/meta.service';

@Injectable({
    providedIn: 'root'
})
export class NewsArticlesCollection {
    public key = 'news-articles';
    public pageData = null;

    constructor(private api: ApiService, private metaService: MetaService) { }


    public get(startAt, limit) {
        this.pageData = this.api.get(this.key + '?_sort=Date:DESC&_start=' + startAt + '&_limit=' + limit, true);
        return this.pageData;
    }
    public getAll() {
        this.pageData = this.api.get(this.key, false);
        return this.pageData;
    }

    public getById(id) {
        this.pageData = this.api.getById(this.key + '/' + id, this.key);
        this.setMetaTags()
        return this.pageData;
    }
    public getByURL(url) {
        this.pageData = this.api.getByURL(this.key, url, true);
        this.setMetaTags()
        return this.pageData;
    }
    public filterByFocusArea(order: string, id?: string, startAt?: number, limit?: number) {
        if (id) {
            this.pageData = this.api.get(this.key + '?focus_area=' + id + '&_sort=Date:' + order + '&_start=' + startAt + '&_limit=' + limit, true);
            return this.pageData;
        }
        else {
            this.pageData = this.api.get(this.key + '?_sort=Date:' + order + '&_start=' + startAt + '&_limit=' + limit, true);
            return this.pageData;
        }

    }


    public setMetaTags() {
        this.pageData.subscribe(data => {
            this.metaService.addMetaTags({
              title:
                data && data.SEO && data.SEO.PageTitle
                  ? data.SEO.PageTitle
                  : 'Alwaleed Philanthropies',
              description:
                data && data.SEO && data.SEO.Description
                  ? data.SEO.Description
                  : null,
              image:
                data && data.SEO && data.SEO.Image
                  ? data.SEO.Image.url
                  : 'https://apwebsite.xyz/assets/media/logo-green.svg',
              type: 'website',
              keywords:
                data && data.SEO && data.SEO.MetaTags ? data.SEO.MetaTags : '',
            });
            this.metaService.removeStructuredData();
            this.metaService.insertSchema(this.metaService.organizationSchema());
            this.metaService.insertSchema(this.metaService.websiteSchema());
        })
    }
}

