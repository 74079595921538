import { Component, Input, OnInit } from '@angular/core';
import { FocusAreas } from '../../../providers/data/interfaces/focus-areas.interface';


@Component({
  selector: 'ap-quote',
  templateUrl: 'quote.component.html',
  styleUrls: ['quote.component.scss'],
})
export class QuoteComponent implements OnInit {
  @Input() title: string;
  @Input() quote: any;


  constructor(
  ) { }


  ngOnInit() {
  }




}
