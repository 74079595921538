import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { MetaService } from '../../meta/meta.service';

@Injectable({
    providedIn: 'root'
})
export class CampaignsCollection {
    public key = 'campaigns';
    public pageData = null;

    constructor(private api: ApiService, private metaService: MetaService) { }


    public get() {
        this.pageData = this.api.get(this.key + '?_sort=StartDate:DESC', true);
        return this.pageData
    }
    getById(id) {
        this.pageData = this.api.get(this.key + '/' + id);
        this.setMetaTags()
        return this.pageData
    }
    public getByURL(url) {
        this.pageData = this.api.getByURL(this.key, url, true);
        this.setMetaTags()
        return this.pageData
    }
    public filterByFocusArea(order: string, id?: string) {
        if (id) {
            this.pageData = this.api.get(this.key + '?FocusArea=' + id + '&_sort=StartDate:' + order, true);
            return this.pageData
        }
        else {
            this.pageData = this.api.get(this.key + '?_sort=StartDate:' + order, true);
            return this.pageData
        }

    }
    public setMetaTags() {
        this.pageData && this.pageData.subscribe(data => {
            this.metaService.addMetaTags({
              title:
                data && data.SEO && data.SEO[0] && data.SEO[0].PageTitle
                  ? data.SEO[0].PageTitle
                  : 'Alwaleed Philanthropies',
              description:
                data && data.SEO && data.SEO[0] && data.SEO[0].Description
                  ? data.SEO[0].Description
                  : null,
              image:
                data && data.SEO && data.SEO[0] && data.SEO[0].Image
                  ? data.SEO[0].Image.url
                  : 'https://apwebsite.xyz/assets/media/logo-green.svg',
              type: 'website',
              keywords:
                data && data.SEO && data.SEO[0] && data.SEO[0].MetaTags
                  ? data.SEO[0].MetaTags
                  : '',
            });
            this.metaService.removeStructuredData();
            this.metaService.insertSchema(this.metaService.organizationSchema());
            this.metaService.insertSchema(this.metaService.websiteSchema());
        })
    }
}

